import { frontendURL } from '../../../../helper/URLHelper';
const SettingsContent = () => import('../Wrapper.vue');
const IndexCampaigns = () => import('./Index.vue');
const IndexBroadcasts = () => import('../broadcasts/Index.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.HEADER',
        icon: 'megaphone',
      },
      children: [
        {
          path: '',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'settings_account_campaigns',
          roles: ['administrator'],
          component: IndexCampaigns,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.BROADCASTS.HEADER',
        icon: 'sound-source',
      },
      children: [
        {
          path: 'broadcasts',
          name: 'broadcasts',
          roles: ['administrator'],
          component: IndexBroadcasts,
        },
      ],
    },
    /*
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.ONGOING.HEADER',
        icon: 'arrow-swap',
      },
      children: [
        {
          path: 'ongoing',
          name: 'ongoing',
          roles: ['administrator'],
          component: IndexCampaigns,
        },
      ],
    },
    */
  ],
};

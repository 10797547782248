<template>
  <div class="flex flex-col items-start pt-8 px-8 pb-0">
    <img v-if="headerImage" :src="headerImage" alt="No image" />
    <h2
      ref="modalHeaderTitle"
      class="text-slate-800 text-lg dark:text-slate-100"
    >
      <span
        v-if="headerTitlePreceding"
        ref="modalHeaderTitlePreceding"
        class="mr-4 ml-2 text-base font-normal text-woot-500 dark:text-woot-500"
      >
        {{ headerTitlePreceding }}
      </span>
      {{ headerTitle }}
    </h2>
    <p
      v-if="headerContent"
      ref="modalHeaderContent"
      class="w-full break-words text-slate-600 mt-2 text-sm dark:text-slate-300"
    >
      {{ headerContent }}
      <span
        v-if="headerContentValue"
        class="font-semibold text-sm text-slate-600 dark:text-slate-300"
      >
        {{ headerContentValue }}
      </span>
    </p>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    headerTitlePreceding: {
      type: String,
      default: '',
    },
    headerTitle: {
      type: String,
      default: '',
    },
    headerContent: {
      type: String,
      default: '',
    },
    headerContentValue: {
      type: String,
      default: '',
    },
    headerImage: {
      type: String,
      default: '',
    },
  },
};
</script>

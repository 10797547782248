/* global axios */
import ApiClient from './ApiClient';

class AgentBotsAPI extends ApiClient {
  constructor() {
    super('agent_bots', { accountScoped: true });
  }

  getBotFlows(botId) {
    return axios.get(`${this.url}/${botId}/flows`);
  }
}

export default new AgentBotsAPI();

const Bot = () => import('./Index.vue');
const EditBot = () => import('./Edit.vue');
const BotFlows = () => import('./components/BotFlows.vue');
const NewBot = () => import('./New.vue');
import { frontendURL } from '../../../../helper/URLHelper';
const SettingsContent = () => import('../Wrapper.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/agent-bots'),
      roles: ['administrator'],
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'agent_bots';
        return {
          headerTitle: 'AGENT_BOTS.HEADER',
          headerButtonText: 'AGENT_BOTS.ADD.TITLE',
          icon: 'bot',
          newButtonRoutes: ['agent_bots_new'],
          showBackButton,
          showNewButton: !showBackButton,
        };
      },
      children: [
        {
          path: '',
          name: 'agent_bots',
          component: Bot,
          roles: ['administrator'],
        },
        {
          path: 'new',
          name: 'agent_bots_new',
          component: NewBot,
          roles: ['administrator'],
        },
        {
          path: ':botId',
          name: 'agent_bots_edit',
          component: EditBot,
          roles: ['administrator'],
        },
        {
          path: ':botId/flows',
          name: 'agent_bots_flows',
          component: BotFlows,
          roles: ['administrator'],
        },
        {
          path: ':botId/finished',
          name: 'agent_bots_finished',
          component: EditBot,
          roles: ['administrator'],
        },
      ],
    },
  ],
};

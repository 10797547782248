import { render, staticRenderFns } from "./HelpCenterLayout.vue?vue&type=template&id=3d27399f&"
import script from "./HelpCenterLayout.vue?vue&type=script&lang=js&"
export * from "./HelpCenterLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/rails/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d27399f')) {
      api.createRecord('3d27399f', component.options)
    } else {
      api.reload('3d27399f', component.options)
    }
    module.hot.accept("./HelpCenterLayout.vue?vue&type=template&id=3d27399f&", function () {
      api.rerender('3d27399f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/routes/dashboard/helpcenter/components/HelpCenterLayout.vue"
export default component.exports
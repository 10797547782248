import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import FlowsAPI from '../../api/flows';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { FLOWS_EVENTS } from '../../helper/AnalyticsHelper/events';

export const state = {
  botFlows: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isLoadingFlows: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getBotFlows: _state => botId => {
    return _state.botFlows
      .filter(flow => flow.agent_bot_id === botId)
      .sort((c2, c1) => c1.created_at?.localeCompare(c2.created_at));
  },
};

export const actions = {
  get: async function getBotFlows({ commit }, botId) {
    commit(types.SET_FLOW_UI_FLAG, { isFetching: true });
    try {
      const response = await FlowsAPI.get(botId);
      commit(types.SET_FLOWS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_FLOW_UI_FLAG, { isFetching: false });
    }
  },
  create: async function createFlows({ commit }, flowObj) {
    commit(types.SET_FLOW_UI_FLAG, { isCreating: true });
    try {
      const response = await FlowsAPI.create(flowObj);
      commit(types.ADD_FLOW, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_FLOW_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_FLOW_UI_FLAG, { isUpdating: true });
    try {
      const response = await FlowsAPI.update(id, updateObj);
      AnalyticsHelper.track(FLOWS_EVENTS.UPDATE_FLOW);
      commit(types.EDIT_FLOW, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_FLOW_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_FLOW_UI_FLAG, { isDeleting: true });
    try {
      await FlowsAPI.delete(id);
      AnalyticsHelper.track(FLOWS_EVENTS.DELETE_FLOW);
      commit(types.DELETE_FLOW, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_FLOW_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_FLOW_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.ADD_FLOW]: MutationHelpers.create,
  [types.SET_FLOWS]: MutationHelpers.set,
  [types.EDIT_FLOW]: MutationHelpers.update,
  [types.DELETE_FLOW]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
